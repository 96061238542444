.bab-covid {
  display: flex;
  padding: var(--spacing);
  background-color: #dedada;
  .bab-icon-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  &.hidden {
    display: none;
  }
}
