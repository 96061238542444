:root {
  --spacing: 1em;
  --spacing-small: calc(var(--spacing) / 2);
  --alt-text-color: white;
  --text-color: #333;
  --app-background-color: #807e7e;
  --body-background-color: #fff;
  --brand-color-accent: rgb(234, 166, 37);
  --ratio: 1.4;
  --measure: 65ch;
  --line-height: var(--ratio);
  --line-height-small: calc(0.8 * var(--ratio));
  --font-basic: Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-size-basis: calc(0.333vw + 1em);
  --font-size-small: 0.75em;
  --font-size-normal: 1em;
  --font-size-large: 1.25em;
  --danger-color: #c8102e;
  --danger-color-alt: #e6d2d5;
  line-height: var(--ratio);
  font-size: var(--font-size-basis);
}
