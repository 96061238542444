.with-sidebar {
  display: flex;
  flex-wrap: wrap;
}

.sidebar {
  flex-basis: 30rem;
  flex-grow: 1;
}

.not-sidebar {
  flex-basis: 0;
  flex-grow: 999;
  min-width: 50%;
  padding: var(--spacing);
}

.bab-hero-img {
  background-position: center 62%;
  background-size: cover;
  min-height: 30vh;
  background-color: black;
  display: flex;
  align-items: baseline;
  &.loaded {
    background-image: url(../../images/rank.jpg);
  }

  .bab-btn {
    margin-left: auto;
    margin-top: auto;
    margin-right: var(--spacing);
    margin-bottom: var(--spacing);
    white-space: nowrap;
    background: var(--brand-color-accent);
    color: black;
  }
}
