.bab-header {
  flex: 1;
  min-width: 100%;
  align-items: center;
  position: sticky;
  top: 0;
  background: var(--body-background-color);
  border-bottom: 1px solid rgba(0,0,0,.15);
  box-shadow: 0px 1px 3px rgba(0,0,0,.2);
}

.bab-header-content{
  display: flex;
  flex-flow: wrap;
  flex: 1;
  align-items: center;
  padding: var(--spacing);
}

.bab-logo {
  flex: none;
  max-width: 12em;
  /* weird thing with iOS stretches height of the anchor */
  margin-bottom: -0.4em;
  svg {
    width: 100%;
  }
}

.bab-nav {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
  ul,
  li {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  li {
    margin: 0 var(--spacing);
  }
  a {
    text-decoration: none;
    color: var(--text-color);
  }
}

.bab-nav-toggle {
  display: none;
  margin-left: auto;
}

@media (max-width: 827px) {
  .bab-nav {
    display: none;
    flex: none;
    ul,
    li {
      display: block;
      text-align: center;
      margin: var(--spacing);
    }
  }
  [aria-expanded="true"] + .bab-nav {
    display: block;
    margin: 0 auto;
  }

  .bab-nav-toggle {
    display: block;
    border: none;
    background: transparent;
  }
}
