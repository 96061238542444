input,
textarea {
  font-size: inherit;
  padding: calc(var(--spacing) / 4) calc(var(--spacing) / 2);
  width: 100%;
  display: block;
  border: 2px solid var(--text-color);
  border-radius: 0;
  outline: 0;
  &:focus {
    border: 2px solid var(--brand-color-accent);
  }
}

input[type="datetime-local"] {
  min-width: calc(100% - 1em);
}

label {
  font-size: 0.75rem;
  display: block;
}

form {
  background: rgb(233, 233, 233);
  border: 1px solid var(--text-color);
  color: var(--text-color);
  padding: var(--spacing) 0;
}

button,
.bab-btn {
  border: 1px solid var(--text-color);
  background: var(--brand-color-accent);
  color: var(--text-color);
  padding: calc(var(--spacing) / 4) calc(var(--spacing) / 2);
  display: inline-flex;
  text-decoration: none;
}

.pac-item,
.pac-item-query {
  font-size: var(--font-size-normal);
  padding: calc(var(--spacing) / 4) calc(var(--spacing) / 2);
}

.bab-form-field {
  &.bab-form-error {
    input {
      border-color: var(--danger-color);
      background-color: var(--danger-color-alt);
    }
  }
  .pristine-error {
    color: var(--danger-color);
    font-size: var(--font-size-small);
  }
}

.bab-form-row {
  display: flex;
  flex-wrap: wrap;
  padding-left: var(--spacing);
  > * {
    min-width: 12em;
    flex: 1;
    margin-right: var(--spacing);
  }
}

.bab-form-action,
.bab-form-header {
  padding: 0 var(--spacing);
}
