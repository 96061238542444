/* mulish-200 - latin */
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 200;
  font-display: fallback;
  src: local(""), url("../../fonts/mulish-v1-latin-200.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/mulish-v1-latin-200.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* mulish-regular - latin */
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local(""),
    url("../../fonts/mulish-v1-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/mulish-v1-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* mulish-700 - latin */
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local(""), url("../../fonts/mulish-v1-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/mulish-v1-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  display: block;
  font-family: "Mulish", sans-serif;
  color: var(--text-color);
  min-height: 100vh;
  background: var(--body-background-color);
}
button {
  touch-action: manipulation;
}
[hidden] {
  display: none;
}

.visually-hidden {
  clip-path: inset(100%) !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.bab-app {
  margin: 0 auto;
  background-color: var(--app-background-color);
}

.bab-main,
.bab-body {
  padding: 0 0 var(--spacing) 0;
  margin: 0 auto;
}

.bab-max-width{
  max-width: 65em;
  margin:0 auto;
}

.bab-main {
  min-height: 100vh;
  /* max-width: 75em; */
  background-color: var(--body-background-color);
}

@media (max-width: 827px) {
  .bab-main,
  .bab-body {
    padding: 0;
  }
  .bab-main {
    padding-bottom: var(--spacing);
  }
}

.bab-content {
  max-width: 48em;
  margin: 0 auto;

  a {
    color: currentColor;
    &:hover {
      color: var(--brand-color-accent);
    }
  }
}

.bab-stack {
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: var(--spacing);
  }
}

.font-size\:small {
  font-size: var(--font-size-small);
}
.font-size\:normal {
  font-size: var(--font-size-normal);
}
.font-size\:large {
  font-size: var(--font-size-large);
}

.bab-promo {
  color: white;
  padding: var(--spacing-small);
  margin: var(--spacing-small);
  display: inline-flex;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.6);
}

.bab-footer {
  background: var(--text-color);
  color: var(--alt-text-color);
  padding: var(--spacing);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  > * {
    flex: 1;
    padding: 0 var(--spacing-small);
  }
  a {
    color: var(--alt-text-color);
  }
}

.bab-icon-button {
  background: transparent;
  display: inline-block;
  border: none;
  stroke: currentColor;
  fill: currentColor;
  text-decoration-skip: objects;
  svg {
    display: inline-block;
    width: 0.75em;
    height: 0.75em;
  }
}

.bab-icon {
  display: inline-block;
  svg {
    width: 0.75em;
    height: 0.75em;
    stroke: currentColor;
    fill: currentColor;
    /* stroke-width: 4; */
    display: inline-block;
    text-decoration-skip: objects;
  }
  &.bab-icon-lg {
    svg {
      width: 1em;
      height: 1em;
    }
  }
}
